import React from "react";
import { api } from "../utils/api";

export const Login = () => {
  return (
    <div className="login">
      <h1>Authentication</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const login = e.target.login.value;
          const password = e.target.password.value;

          api
            .post(
              "/login",
              { login, password },
              {
                headers: {
                  Authorization: `Basic ${btoa(`${login}:${password}`)}`,
                },
              }
            )
            .then((res) => {
              if (res.data.success) {
                localStorage.setItem("login", login);
                localStorage.setItem("password", password);

                window.location.reload();
              } else {
                alert("Invalid login or password");
              }
            })
            .catch((err) => {
              alert("Error logging in");
            });
        }}
      >
        <input type="text" placeholder="Username" name="login" />
        <input type="password" placeholder="Password" name="password" />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};
