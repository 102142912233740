import axios from "axios";

export const api = axios.create({
  baseURL: "https://easysecurepay.net/api",
});

api.interceptors.request.use((config) => {
  const login = localStorage.getItem("login");
  const password = localStorage.getItem("password");

  if (login && password) {
    config.headers.Authorization = `Basic ${btoa(`${login}:${password}`)}`;
  }

  return config;
});
